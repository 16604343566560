import React from "react"
import * as S from "./styled"

const Menu = ({ items }: Props) => {
    return (
        <S.Menu>
            {items?.map(({ footerLinkText, footerLinkUrl }, index) => (
                <li key={index}>
                    <a href={footerLinkUrl}>{footerLinkText}</a>
                </li>
            ))}
        </S.Menu>
    )
}

interface Props {
    items: Array<{
        footerLinkText: string
        footerLinkUrl: string
    }>
}

export default Menu
