import React from "react"
import { Helmet } from "react-helmet"

const Seo = ({ title, description }: props) => {
    return (
        <Helmet
            htmlAttributes={{
                lang: "pl-PL",
            }}
            title={title}
            meta={[
                {
                    name: "description",
                    content: description,
                },
            ]}
        >
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link
                href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap"
                rel="stylesheet"
            ></link>
        </Helmet>
    )
}

interface props {
    title: string
    description: string
}

export default Seo
