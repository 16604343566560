import React, { useState } from "react"
import GridContainer from "../../styled/GridContainer"
import * as S from "./styled"
import { useQuery } from "./useQuery"
import Logo from "@icons/fp-logo.svg"
import Menu from "./menu/Menu"

const Footer = () => {
    const { privacy, footerEmail, footerMenu, menuHeader } = useQuery()
    const [copyingResult, setCopyingResult] = useState<false | string>(false)

    const copyText = () => {
        navigator.clipboard
            .writeText(footerEmail)
            .then(() => {
                setCopyingResult("Pomyślnie skopiowano!")
            })
            .catch(_ => {
                setCopyingResult("Kopiowanie nieudane!")
            })
    }
    return (
        <S.Footer>
            <GridContainer>
                <div className="grid grid-cols-12">
                    <div className="col-span-12 md:col-span-5">
                        <Logo />
                        <S.Privacy
                            dangerouslySetInnerHTML={{ __html: privacy }}
                        />
                    </div>
                    <div className="col-span-12 md:col-span-2 md:col-start-7">
                        <S.Header
                            dangerouslySetInnerHTML={{ __html: menuHeader }}
                        />
                        <Menu items={footerMenu} />
                    </div>
                    <div className="col-span-12 md:col-span-4">
                        <S.Header
                            dangerouslySetInnerHTML={{ __html: "Email:" }}
                        />
                        <a href={`mailto:${footerEmail}`}>
                            <strong>{footerEmail}</strong>
                        </a>
                        <S.CopyButton onClick={() => copyText()}>
                            kopiuj do schowka
                        </S.CopyButton>
                        {copyingResult && <span>{copyingResult}</span>}
                    </div>
                </div>
            </GridContainer>
        </S.Footer>
    )
}

export default Footer
