import styled from "styled-components"

const Menu = styled.ul`
    display: flex;
    flex-direction: column;

    li {
        margin-bottom: 10px;
    }
`

export { Menu }
