import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"

const List = styled.ul<{ active: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin-bottom: 50px;

    ${breakpoints.sm} {
        flex-direction: row;
        padding: 0;
        margin-bottom: 0;
    }
`

const ListItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${breakpoints.sm} {
        margin-left: 40px;
    }
`

const IconWrapper = styled.div`
    ${breakpoints.sm} {
        display: none;
    }
`

const MenuLink = styled.a<{ button: boolean }>`
    width: ${({ button }) => (button ? "auto" : "100%")};
    transition: ease-in-out 0.3s;
    background: ${({ button }) =>
        button ? cssVariables.colors.main : "transparent"};
    color: ${({ button }) => (button ? "#fff" : "#000")};
    text-transform: uppercase;
    font-weight: 700;
    padding: ${({ button }) => (button ? "5px 20px" : "20px 0")};

    ${breakpoints.sm} {
        margin-left: auto;
        transition-delay: 0s;

        &:hover {
            transition: 0.1s;
            color: ${({ button }) =>
                button ? "#fff" : cssVariables.colors.main};
        }
    }

    ${breakpoints.sm} {
        font-size: ${cssVariables.fonts.small};
        font-weight: 300;
        padding: 5px 20px;
    }

    &.active {
        color: ${cssVariables.colors.main};
        font-weight: 700;
    }
`

export { List, ListItem, IconWrapper, MenuLink }
