import React, { useEffect } from "react"
import * as S from "./page/styled"
import Header from "./header/Header"
import Footer from "./footer/Footer"
import Seo from "../common/Seo"
import { useAppSelector } from "../../store/hooks"
import { selectPopup } from "../../store/gameSlice"
const Template = ({ children, title, description }: Props) => {
    const popup = useAppSelector(selectPopup)

    useEffect(() => {
        if (popup) {
            document.body.classList.add("menu-open")
        } else {
            document.body.classList.remove("menu-open")
        }
    }, [popup])
    return (
        <S.Main blurred={popup}>
            <Seo title={title} description={description} />
            <Header />
            {children}
            <Footer />
        </S.Main>
    )
}

interface Props {
    children: React.ReactNode
    title: string
    description: string
}

export default Template
