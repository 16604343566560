import React from "react"
import * as S from "./styled"
const Instructions = ({ header, instructions, mobile }: Props) => {
    return (
        <S.Wrapper mobile={mobile}>
            <S.Header dangerouslySetInnerHTML={{ __html: header }} />
            <S.Instructions>
                {instructions?.map(({ instruction }, index) => (
                    <li
                        key={index}
                        dangerouslySetInnerHTML={{
                            __html: instruction,
                        }}
                    />
                ))}
            </S.Instructions>
        </S.Wrapper>
    )
}

interface Props {
    header: string
    instructions: Array<{
        instruction: string
    }>
    mobile?: true
}

export default Instructions
