import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"

const Wrapper = styled.div<{ mobile?: true }>`
    display: ${({ mobile }) => (mobile ? "block" : "none")};

    ${breakpoints.sm} {
        display: ${({ mobile }) => (mobile ? "none" : "block")};
    }
`
const Header = styled.span`
    display: block;
    font-weight: 600;
    margin-bottom: 30px;

    ${breakpoints.md} {
        font-size: ${cssVariables.fonts.big};
        margin: 30px 0;
    }
`

const Instructions = styled.ol<{ mobile?: true }>`
    display: flex;
    flex-direction: column;
    counter-reset: order-counter;
    margin-bottom: 20px;

    li {
        counter-increment: order-counter;
        margin-bottom: 10px;

        &:before {
            content: counter(order-counter) ". ";
            font-weight: 600;
        }
    }
`

export { Wrapper, Header, Instructions }
