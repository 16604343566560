import { graphql, useStaticQuery } from "gatsby"

export const useQuery = () => {
    const data: useQuery = useStaticQuery(
        graphql`
            query {
                wp {
                    footer {
                        acf {
                            footerEmail
                            footerMenu {
                                footerLinkText
                                footerLinkUrl
                            }
                            menuHeader
                            privacy
                        }
                    }
                }
            }
        `
    )
    return data.wp.footer.acf
}

export interface useQuery {
    wp: {
        footer: {
            acf: {
                footerEmail: string
                footerMenu: Array<{
                    footerLinkText: string
                    footerLinkUrl: string
                }>
                menuHeader: string
                privacy: string
            }
        }
    }
}
