import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"
import styled from "styled-components"

const Footer = styled.footer`
    background-color: ${cssVariables.colors.dark};
    padding: 20px 0;
    color: #fff;

    ${breakpoints.md} {
        padding: 50px 0;
    }

    svg {
        margin-bottom: 30px;
    }

    a {
        &:hover {
            color: ${cssVariables.colors.main};
        }
    }
`

const Header = styled.h4`
    color: ${cssVariables.colors.grayDark};
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 15px;
    margin-top: 50px;

    ${breakpoints.md} {
        margin-top: 0;
    }
`

const CopyButton = styled.button`
    display: block;
    color: ${cssVariables.colors.main};
    margin-top: 10px;
`

const Privacy = styled.span`
    a {
        color: ${cssVariables.colors.main};
    }
`

export { Footer, Header, CopyButton, Privacy }
