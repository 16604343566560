import { breakpoints } from "@breakpoints"
import { cssVariables } from "@cssVariables"
import styled from "styled-components"

const Hello = styled.h1`
    font-size: ${cssVariables.fonts.big};
    font-family: "Malleable";
    line-height: 1;

    ${breakpoints.md} {
        font-size: 80px;
    }
`

const Grid = styled.div`
    display: grid;
    align-items: flex-start;

    svg {
        width: 100%;
        height: 100%;
        margin-right: -20px;

        ${breakpoints.md} {
            margin-right: -40px;
        }
    }

    ${breakpoints.lg} {
        align-items: center;
    }
`

const Header = styled.h2`
    font-size: 24px;
    font-family: "Malleable";
    font-weight: 200;

    ${breakpoints.md} {
        font-size: 50px;
    }
`

const LamaWrapper = styled.div<{ boxVisible: boolean }>`
    margin-right: -20px;
    z-index: -1;
    margin-bottom: -40px;

    .lama-text,
    .lama-box {
        transition: 0.5s;
        transition-delay: 1s;
        opacity: ${({ boxVisible }) => (boxVisible ? 1 : 0)};
    }

    .lama-text {
        transition-delay: 1.5s;
    }

    ${breakpoints.lg} {
        margin-right: -40px;
    }
`

export { Hello, Grid, Header, LamaWrapper }
