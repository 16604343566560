import React from "react"
import * as S from "./styled"

const Menu = ({ active, items }: props) => {
    return (
        <S.List active={active}>
            {items?.map(({ linkText, linkUrl }, index) => (
                <S.ListItem key={index}>
                    <S.MenuLink
                        href={linkUrl}
                        dangerouslySetInnerHTML={{ __html: linkText }}
                        button={index === items.length - 1}
                    />
                </S.ListItem>
            ))}
        </S.List>
    )
}

interface props {
    active: boolean
    items: Array<{
        linkText: string
        linkUrl: string
    }>
}

export default Menu
