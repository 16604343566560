import styled from "styled-components"
import { breakpoints } from "@breakpoints"
import LogoIcon from "@icons/logo.svg"
import { cssVariables } from "@cssVariables"

const Header = styled.header<{ isScrolling: boolean }>`
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    height: ${({ isScrolling }) => (isScrolling ? "40px" : "80px")};
    z-index: 999;
    top: 0;
    transition: 0.5s;
    background-color: #fff;
    border-bottom: 1px solid ${cssVariables.colors.gray};
    overflow: hidden;

    ${breakpoints.md} {
        height: ${({ isScrolling }) => (isScrolling ? "60px" : "120px")};
    }

    .company {
        opacity: ${({ isScrolling }) => (isScrolling ? "0" : "1")};
        transform: ${({ isScrolling }) =>
            isScrolling ? "translateY(-10px)" : "translateY(0)"};
        transition: 0.3s;
    }
`

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Logo = styled(LogoIcon)`
    width: ${({ $isScrolling }) => ($isScrolling ? "100px" : "150px")};
    margin-top: ${({ $isScrolling }) => ($isScrolling ? "5px" : "-10px")};
    transition: 0.3s;

    ${breakpoints.md} {
        width: ${({ $isScrolling }) => ($isScrolling ? "150px" : "200px")};
    }
`

const Nav = styled.nav<{ active: boolean; isScrolling: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: ${({ isScrolling }) => (isScrolling ? "40px" : "80px")};
    left: ${({ active }) => (active ? "0%" : "-100%")};
    width: 100%;
    height: calc((100vh - 60px) - calc(100vh - 100%));
    transition: 0.3s ease-in-out;
    background: #fff;
    overflow: auto;

    ${breakpoints.sm} {
        flex-direction: row;
        background: none;
        position: relative;
        height: auto;
        left: auto;
        top: auto;
        width: auto;
        align-items: center;
    }
`

export { Header, Wrapper, Logo, Nav }
