import React, { useState, useEffect, useRef } from "react"
import * as S from "./styled"
import GridContainer from "../../../components/styled/GridContainer"
import throttle from "lodash.throttle"
import { useQuery } from "./useQuery"
import Hamburger from "./hamburger/Hamburger"
import { Link } from "gatsby"
import Menu from "./menu/Menu"

const openMenuBodyClass = "menu-open"

const Header = () => {
    const [active, setActive] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0)
    const [isScrolling, setIsScrolling] = useState(false)
    const prevPosition = useRef(scrollPosition)
    const menu = useQuery()

    useEffect(() => {
        const calcHeight = throttle(() => {
            const currentScroll = window.pageYOffset
            const IsScrolling = prevPosition.current < currentScroll
            prevPosition.current = window.pageYOffset

            setIsScrolling(currentScroll > 0)
            setScrollPosition(currentScroll)
        }, 500)
        window.addEventListener("scroll", calcHeight)
        return () => {
            window.removeEventListener("scroll", calcHeight)
        }
    }, [])

    useEffect(() => {
        if (active) {
            document.body.classList.add(openMenuBodyClass)
        } else {
            document.body.classList.remove(openMenuBodyClass)
        }
    }, [active])

    return (
        <S.Header isScrolling={isScrolling}>
            <GridContainer>
                <S.Wrapper>
                    <Link to="/">
                        <S.Logo $isScrolling={isScrolling} />
                    </Link>
                    <S.Nav isScrolling={isScrolling} active={active}>
                        <Menu items={menu} active={active} />
                    </S.Nav>
                    <Hamburger
                        isScrolling={isScrolling}
                        active={active}
                        onClick={() => setActive(active => !active)}
                    />
                </S.Wrapper>
            </GridContainer>
        </S.Header>
    )
}

export default Header
