import { graphql, useStaticQuery } from "gatsby"

export const useQuery = () => {
    const data: useQuery = useStaticQuery(
        graphql`
            query {
                wp {
                    header {
                        acf {
                            menu {
                                linkText
                                linkUrl
                            }
                        }
                    }
                }
            }
        `
    )
    return data.wp.header.acf.menu
}

export interface useQuery {
    wp: {
        header: {
            acf: {
                menu: Array<{
                    linkText: string
                    linkUrl: string
                }>
            }
        }
    }
}
