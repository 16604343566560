import React from "react"
import * as S from "./styled"
const Hamburger = ({ isScrolling, active, onClick }: props) => {
    return (
        <S.Wrapper onClick={onClick}>
            <S.Part active={active} />
            <S.Part active={active} />
            <S.Part active={active} />
            <S.Part active={active} />
            {!isScrolling && (
                <S.Text dangerouslySetInnerHTML={{ __html: "menu" }} />
            )}
        </S.Wrapper>
    )
}

export default Hamburger

interface props {
    isScrolling: boolean
    active: boolean
    onClick: () => void
}
